<template>
  <div class="flex-row justify-center items-center relative page">
    <div class="flex-row items-center pos">
      <img class="image_2" src="../../assets/5cfdddca8a966d03c4c2478c0d10f981.png" />
      <img class="ml-4 image_3" src="../../assets/acebd2e2c49d37ac703c7e7632940f44.png" />
      <img class="ml-4 image_3" src="../../assets/5a5e14b396fd10dbf08609d0dcda4b0e.png" />
      <img class="ml-4 image_3" src="../../assets/7ccac576692a501a7faff75476e3b25c.png" />
      <img class="ml-4 image_4" src="../../assets/0c82f456b52ddc207f9a157fed58b54a.png" />
      <img class="ml-4 image_5" src="../../assets/5d45f02adfafa2beb07cb478579f48fb.png" />
      <img class="ml-4 image_6" src="../../assets/767b6c08238ad94377479ef8a25fb307.png" />
    </div>
    <div class="flex-row justify-start items-center image-wrapper">
      <img class="image" src="../../assets/b00fe0f08c33a8067cc4192db147c177.png" />
    </div>
    <div class="group pos_2">
      <span class="font text">Contact Us:</span>
      <span class="font text_2">contact@autogame.ai</span>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    padding: 4.2708vw 11.1979vw 3.9063vw;
    background-color: #222222;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 100%; */
  }
  .pos {
    position: absolute;
    left: 11.1979vw;
    top: 50%;
    transform: translateY(-50%);
  }
  .image_2 {
    width: 2.3958vw;
    height: 1.5104vw;
  }
  .image_3 {
    width: 1.5104vw;
    height: 1.1979vw;
  }
  .image_4 {
    width: 1.4583vw;
    height: 1.1979vw;
  }
  .image_5 {
    width: 1.9271vw;
    height: 1.1979vw;
  }
  .image_6 {
    width: 3.4141vw;
    height: 1.1979vw;
  }
  .image-wrapper {
    width: 15.0979vw;
  }
  .image {
    width: 8.3333vw;
    height: 5.4167vw;
  }
  .group {
    line-height: 0.6115vw;
  }
  .pos_2 {
    position: absolute;
    right: 11.8979vw;
    top: 50%;
    transform: translateY(-50%);
  }
  .font {
    font-size: 0.9375vw;
    font-family: Afacad;
    font-weight: 700;
    color: #ffffff;
  }
  .text {
    line-height: 0.6115vw;
  }
  .text_2 {
    line-height: 0.8021vw;
    text-decoration: underline;
  }
</style>