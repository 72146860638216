<template>
  <div class="flex-col justify-start items-center page">
    <img class="image" src="../../assets/91151acbe678b5b271ae4daa323f3459.png" />
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    padding-top: 4.1667vw;
    background-color: #222222;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }
  .image {
    width: 100vw;
    height: 47.9167vw;
  }
</style>