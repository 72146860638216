<template>
  <div class="flex-col relative page">
    <span class="self-start text">应用多项实时生成游戏内容能力</span>
    <span class="self-start font text_5">更强情绪价值</span>
    <div class="self-end section_6"></div>
    <div class="flex-col items-start section_3 pos_3">
      <div class="group_11">
        <span class="font_3 text_10">我们认为</span>
        <span class="font_4 text_11">chatbot</span>
        <span class="font_3">不够极致，所以做了有故事、有个性、能互动、会说话的</span>
        <span class="font_4 text_12">AI</span>
      </div>
      <div class="section_9 mt-239"></div>
    </div>
    <div class="group pos_2">
      <span class="font text_2">一键创建可深度互动的</span>
      <span class="font_2">AI</span>
      <span class="font text_3">游伴</span>
    </div>
    <span class="font_3 text_4 pos_4">基于关键词定向检索角色知识，结合游戏世界观生成角色</span>
    <img class="image pos_5" src="../../assets/662166a53813c1f2aab03e3a4907c248.png" />
    <div class="group_9 pos_10">
      <span class="font">真正的游戏具身</span>
      <span class="font_2">AI</span>
    </div>
    <img class="image_10 pos_11" src="../../assets/c0c3a7361e2ae194b534488294e0329f.png" />
    <div class="group_4 pos_8">
      <span class="font_4">GA</span>
      <span class="font_3">感知环境并编排角色行为，创造玩家专属的特殊情节</span>
    </div>
    <img class="image_3 pos_9" src="../../assets/6f0ba6ce81bef1daf8dfcc5bd5cdcb35.png" />
    <div class="flex-col justify-start items-center text-wrapper pos_6">
      <span class="text_6">我把我们所有珍贵的回忆都收集在这里了。</span>
    </div>
    <img class="image_14 pos_15" src="../../assets/0c8cf17dd2db96d0ce897b9f8ccac09c.png" />
    <div class="flex-col section pos">
      <div class="self-start section_2"></div>
      <div class="flex-row items-end self-stretch group_2">
        <div class="flex-col group_5">
          <div class="flex-col self-stretch">
            <div class="self-end section_4"></div>
            <span class="mt-4 self-start font_5 text_7">环境生成</span>
          </div>
          <span class="mt-10 self-start font_6 text_8">生成与真实场景映射的虚拟环境</span>
        </div>
        <img class="image_4" src="../../assets/7eb181dcd9e04acb1674901ccd9761d8.png" />
        <img class="image_5" src="../../assets/2aa243ada6c3ad950716c7aeaa040275.png" />
        <div class="flex-col justify-start items-center relative group_3">
          <img class="image_6" src="../../assets/717f2436a4b28c2e071c6cb5dfbb697f.png" />
          <img class="image_2 pos_7" src="../../assets/537872f56bc68eab455bae8f5e1d02eb.png" />
        </div>
      </div>
      <div class="flex-col self-stretch group_6">
        <div class="flex-row items-center self-stretch">
          <div class="flex-col group_8">
            <div class="flex-col self-stretch">
              <div class="self-end section_5"></div>
              <span class="mt-4 self-start font_5">角色生成</span>
            </div>
            <div class="mt-10 self-start group_10">
              <span class="font_6">形象、动画、背景故事、数值、能力</span>
              <span class="font_7 text_9">etc</span>
            </div>
          </div>
          <img class="image_9" src="../../assets/18ea91154eced37557fede49c4de35cf.png" />
          <div class="flex-row group_7">
            <img class="image_8" src="../../assets/decb5b4173e2ce4aa74007ad807141a2.png" />
            <img class="ml-14 image_7" src="../../assets/297a16f9c746c6d165af6cd579e917fa.png" />
          </div>
        </div>
        <div class="flex-row items-center self-stretch relative">
          <span class="font_5">角色行为</span>
          <div class="flex-col justify-start items-start relative group_12">
            <div class="section_7"></div>
            <img class="image_11 pos_12" src="../../assets/3f2cf0079e5a54daa04f4486ceab9852.png" />
            <img class="image_12 pos_13" src="../../assets/91a7dc6c190b462b98a416278506c2fe.png" />
          </div>
          <span class="font_6 text_13 pos_14">行为树逻辑+大语言模型驱动 AI agent 行动</span>
        </div>
        <div class="self-start section_8"></div>
        <div class="flex-row items-center self-stretch group_13">
          <div class="flex-col items-start">
            <span class="font_5 text_14">实时语音合成</span>
            <span class="mt-8 font_7 text_15">Bert-Vits2, FishSpeech</span>
          </div>
          <img class="image_13 ml-249" src="../../assets/b402c03f82b832b90f6f4e006b5dc7a1.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .mt-239 {
    margin-top: 12.4479vw;
  }
  .ml-249 {
    margin-left: 12.9688vw;
  }
  .page {
    padding: 7.9167vw 0 8.0729vw 16.3542vw;
    background-color: #222222;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
  }
  .text {
    color: #ffffff;
    font-size: 2.3958vw;
    font-family: Alibaba PuHuiTi;
    font-weight: 700;
    line-height: 2.2281vw;
  }
  .font {
    font-size: 1.0417vw;
    font-family: Alibaba PuHuiTi;
    line-height: 0.9833vw;
    font-weight: 700;
    color: #ffffff;
  }
  .text_5 {
    margin-left: 37.3958vw;
    margin-top: 7.3958vw;
    line-height: 0.9771vw;
  }
  .section_6 {
    margin-top: 9.6875vw;
    background-color: #3d3d3d;
    width: 36.5104vw;
    height: 15.7292vw;
  }
  .section_3 {
    padding-top: 20.2083vw;
    background-image: url('../../assets/60ff159e2b4b3f517aeaec33630ef8e5.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 26.0417vw;
  }
  .pos_3 {
    position: absolute;
    right: 11.875vw;
    top: 10.2604vw;
  }
  .group_11 {
    margin-left: -8.4375vw;
    line-height: 0.5005vw;
  }
  .font_3 {
    font-size: 0.5208vw;
    font-family: Alibaba PuHuiTi;
    line-height: 0.5005vw;
    color: #ffffff;
  }
  .text_10 {
    line-height: 0.4729vw;
  }
  .font_4 {
    font-size: 0.5208vw;
    font-family: Afacad;
    line-height: 0.3427vw;
    color: #ffffff;
  }
  .text_11 {
    line-height: 0.3516vw;
  }
  .text_12 {
    line-height: 0.3365vw;
  }
  .section_9 {
    margin-left: 0.4167vw;
    background-color: #333c46;
    width: 0.625vw;
    height: 0.625vw;
  }
  .group {
    line-height: 0.976vw;
  }
  .pos_2 {
    position: absolute;
    right: 32.3375vw;
    top: 9.4604vw;
  }
  .text_2 {
    line-height: 0.976vw;
  }
  .font_2 {
    font-size: 1.0417vw;
    font-family: Afacad;
    line-height: 0.674vw;
    font-weight: 700;
    color: #ffffff;
  }
  .text_3 {
    line-height: 0.9437vw;
  }
  .text_4 {
    line-height: 0.5vw;
  }
  .pos_4 {
    position: absolute;
    right: 32.6458vw;
    top: 10.6125vw;
  }
  .image {
    border-radius: 5.2083vw;
    width: 18.0729vw;
    height: 3.8021vw;
  }
  .pos_5 {
    position: absolute;
    right: 28.3333vw;
    top: 11.4063vw;
  }
  .group_9 {
    line-height: 0.9833vw;
  }
  .pos_10 {
    position: absolute;
    right: 37.8792vw;
    bottom: 21.7906vw;
  }
  .image_10 {
    border-radius: 5.2083vw;
    width: 14.6875vw;
    height: 2.6563vw;
  }
  .pos_11 {
    position: absolute;
    right: 31.7188vw;
    bottom: 18.1771vw;
  }
  .group_4 {
    line-height: 0.5005vw;
  }
  .pos_8 {
    position: absolute;
    right: 32.9156vw;
    top: 18.737vw;
  }
  .image_3 {
    border-radius: 5.2083vw;
    width: 14.2188vw;
    height: 3.2292vw;
  }
  .pos_9 {
    position: absolute;
    right: 32.1875vw;
    top: 19.5313vw;
  }
  .text-wrapper {
    padding: 0.9375vw 0 0.625vw;
    background-image: url('../../assets/9b423f1c69c124c70ff38038cdbad6e0.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 15.9375vw;
  }
  .pos_6 {
    position: absolute;
    right: 1.3542vw;
    top: 17.1354vw;
  }
  .text_6 {
    color: #ffffff;
    font-size: 0.8333vw;
    font-family: VonwaonBitmap 0.625vw;
    line-height: 1.0417vw;
    width: 9.5833vw;
  }
  .image_14 {
    width: 1.6146vw;
    height: 0.625vw;
  }
  .pos_15 {
    position: absolute;
    right: 37.8646vw;
    bottom: 8.0729vw;
  }
  .section {
    padding: 7.0313vw 14.4792vw 4.7917vw;
    background-image: url('../../assets/e935c1ae00de646b4aa56dd8b049d947.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
  }
  .section_2 {
    background-color: #607d63;
    width: 0.625vw;
    height: 3.3854vw;
  }
  .group_2 {
    margin-top: 6.9792vw;
  }
  .group_5 {
    width: 14.5927vw;
  }
  .section_4 {
    background-color: #636363;
    width: 6.875vw;
    height: 0.05208vw;
  }
  .font_5 {
    font-size: 1.7708vw;
    font-family: Alibaba PuHuiTi;
    line-height: 1.6594vw;
    font-weight: 700;
    color: #ffffff;
  }
  .text_7 {
    line-height: 1.6365vw;
  }
  .font_6 {
    font-size: 0.7813vw;
    font-family: Alibaba PuHuiTi;
    line-height: 0.7292vw;
    color: #ffffff;
  }
  .text_8 {
    line-height: 0.7312vw;
  }
  .image_4 {
    margin-left: 0.4688vw;
    width: 3.2292vw;
    height: 4.4797vw;
  }
  .image_5 {
    margin-left: 1.7708vw;
    width: 3.3365vw;
    height: 3.6589vw;
  }
  .group_3 {
    margin-left: 1.9792vw;
    padding-top: 3.5417vw;
    width: 3.2292vw;
    height: 6.776vw;
  }
  .image_6 {
    width: 3.2292vw;
    height: 3.2292vw;
  }
  .image_2 {
    width: 3.2292vw;
    height: 6.4583vw;
  }
  .pos_7 {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .group_6 {
    margin-top: 2.2917vw;
  }
  .group_8 {
    width: 15.3286vw;
  }
  .section_5 {
    background-color: #636363;
    width: 7.6042vw;
    height: 0.05208vw;
  }
  .group_10 {
    line-height: 0.7292vw;
  }
  .font_7 {
    font-size: 0.7813vw;
    font-family: Afacad;
    color: #ffffff;
  }
  .text_9 {
    line-height: 0.4406vw;
  }
  .image_9 {
    width: 3.125vw;
    height: 4.2188vw;
  }
  .group_7 {
    margin-left: 1.3542vw;
  }
  .image_8 {
    margin-top: 0.4167vw;
    width: 4.1667vw;
    height: 4.6354vw;
  }
  .image_7 {
    width: 4.4271vw;
    height: 5.0521vw;
  }
  .group_12 {
    margin-left: 0.2083vw;
    padding: 2.8125vw 0 5vw;
    width: 23.4896vw;
  }
  .section_7 {
    background-color: #636363;
    width: 12.3438vw;
    height: 0.05208vw;
  }
  .image_11 {
    width: 7.7604vw;
    height: 7.7083vw;
  }
  .pos_12 {
    position: absolute;
    right: 5.0521vw;
    top: 0;
  }
  .image_12 {
    width: 7.7083vw;
    height: 7.7083vw;
  }
  .pos_13 {
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .text_13 {
    line-height: 0.7844vw;
  }
  .pos_14 {
    position: absolute;
    left: 0;
    bottom: 1.875vw;
  }
  .section_8 {
    margin-left: 11.3021vw;
    margin-top: 1.5625vw;
    background-color: #636363;
    width: 11.875vw;
    height: 0.05208vw;
  }
  .group_13 {
    padding-top: 0.2083vw;
  }
  .text_14 {
    line-height: 1.6557vw;
  }
  .text_15 {
    line-height: 0.6839vw;
  }
  .image_13 {
    width: 4.6354vw;
    height: 2.2396vw;
  }
</style>