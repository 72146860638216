import Vue from 'vue';
import VueRouter from 'vue-router';
import B2 from '../pages/b2/b2.vue';
import B1 from '../pages/b1/b1.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'b2',
    component: B2,
  },
  {
    path: '/b1',
    name: 'b1',
    component: B1,
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;