<template>
  <div class="flex-col justify-start page">
    <div class="flex-col section">
      <div class="flex-row justify-between items-end self-stretch">
        <img class="image" src="../../assets/7267a799987a93362c2d36a160c4eae0.png" />
        <div class="flex-row items-end group">
          <div class="flex-row section_2">
            <div class="flex-row shrink-0">
              <span class="font text">技术</span>
              <span class="font ml-236">服务</span>
            </div>
            <span class="font text_2 ml-261">产品</span>
          </div>
          <img class="image_2 ml-208" src="../../assets/379515be5920f880b3ec06b5ddf34de1.png" />
        </div>
      </div>
      <div class="grow">  </div>
      <div class="flex-row justify-between items-center self-stretch group_2">
        <div class="flex-col items-start">
          <img class="image_3" src="../../assets/97aeaf1f45d1401d89f020cecac20c75.png" />
          <span class="text_3 mt-31">让每个灵感，都成为游戏</span>
        </div>
        <div class="flex-col justify-start items-center relative group_3">
          <img class="image_5" src="../../assets/51c7ed3b486973bad18d91deab879c4e.png" />
          <img class="image_4 pos" src="../../assets/3b40df0a0e9b83a316d6a2b41c1ca7d2.png" />
        </div>
      </div>
      <div class="grow">  </div>
      <img class="self-center image_6" src="../../assets/206a406aac64ae7618c89c74c1d84962.png" />
    </div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .ml-236 {
    margin-left: 12.2917vw;
  }
  .ml-261 {
    margin-left: 13.5937vw;
  }
  .ml-208 {
    margin-left: 10.8333vw;
  }
  .mt-31 {
    margin-top: 1.6146vw;
  }
  .page {
    background-color: #222222;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
  }
  .section {
    padding: 1.5625vw 4.7396vw 3.4375vw;
    background-image: url('../../assets/5d13fcf3b5b37cbc2ca5e815fb8a3529.png');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
  }
  .image {
    width: 3.2292vw;
    height: 2.0833vw;
  }
  .group {
    margin-right: 1.5104vw;
  }
  .section_2 {
    padding: 0.8333vw 11.0417vw;
    background-color: #3d3d3d99;
    border-radius: 0.625vw;
    backdrop-filter: blur(0.5208vw);
    height: 3.125vw;
  }
  .font {
    font-size: 1.5104vw;
    font-family: Alibaba PuHuiTi;
    line-height: 1.3896vw;
    font-weight: 700;
    color: #ffffff;
  }
  .text {
    line-height: 1.388vw;
  }
  .text_2 {
    margin-right: 1.25vw;
    line-height: 1.3911vw;
  }
  .image_2 {
    width: 3.75vw;
    height: 2.0833vw;
  }
  .group_2 {
    padding: 0 7.7083vw;
  }
  .image_3 {
    width: 40.5208vw;
    height: 4.0625vw;
  }
  .text_3 {
    margin-left: 0.3125vw;
    color: #ffffff;
    font-size: 2.1354vw;
    font-family: Alibaba PuHuiTi;
    line-height: 2.037vw;
  }
  .group_3 {
    margin-right: 7.6563vw;
    padding: 0.9375vw 0;
    width: 8.5938vw;
  }
  .image_5 {
    border-radius: 0.1563vw;
    width: 0.9896vw;
    height: 1.7188vw;
  }
  .image_4 {
    width: 8.5938vw;
    height: 3.6458vw;
  }
  .pos {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .image_6 {
    border-radius: 0.1563vw;
    width: 1.7188vw;
    height: 0.9896vw;
  }
</style>