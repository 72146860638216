<template>
  <div class="flex-col justify-start items-center page">
    <div class="grow"></div>
    <img class="image" v-show="showFirstImage" src="../../assets/e6d6b29fcb4e8c004bc9257c4516c620.png" />
    <img class="image" v-show="!showFirstImage" src="../../assets/91151acbe678b5b271ae4daa323f3459.png" />
    <div class="grow"></div>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {
        showFirstImage: true,
      };
    },
    mounted() {
      setInterval(() => {
        this.showFirstImage = !this.showFirstImage;
      }, 5000);
    },

    methods: {},
  };
</script>

<style scoped lang="css">
  .page {
    background-color: #222222;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 100vh;
  }
  .image {
    width: 100vw;
    height: 47.9688vw;
  }
</style>
